import { ComponentType } from "react";
import IOrganisationContext from "../../interfaces/organisation-context.interface";

const withAuthorizationRoute = <P extends object>(component: ComponentType<P>, organisation: IOrganisationContext, allowedPermissions?: string[], unauthorizedComponent?: any) => {
    const { hasAnyPermission } = organisation;
    const hasPermissions = allowedPermissions ? hasAnyPermission(allowedPermissions) : true;
    return hasPermissions ? component : unauthorizedComponent || null;
};

export default withAuthorizationRoute;

