import React, { FC, useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import getAllowedFields from '../../../api/get-allowed-fields/get-allowed-fields';
import getIsoCountryCodes from '../../../api/get-iso-country-codes/get-iso-country-codes';
import { camelCaseToSentenceCase } from '../../../utilities/string-functions';
import { FormikValues } from 'formik';
import { IGetAllowedFieldsResponse } from '../../../interfaces/IGetAllowedFieldsResponse';
import { ICountryChoices } from "../../../interfaces/ICountryChoices";
import { IRegionChoices } from "../../../interfaces/IRegionChoices";
import { IAddressFormContext } from "../../../interfaces/IAddressFormContext";
import { sortAddressFields } from '../../../utilities/sort-functions';

const AddressFormContext = React.createContext<IAddressFormContext>(
    {
        countryISOCode: 'US',
        apiUrl: '',
        apiKey: ''
    }
);

const AddressFormProvider: FC<IAddressFormContext> = (
    {
        apiUrl: initialApiURL,
        apiKey: initialApiKey,
        countryISOCode: initialCountryCode,
        customErrorMessages: initialCustomErrorMessages,
        customLabelOverrides: initialCustomLabelOverrides,
        defaultInitialValues: initialDefaultInitialValues,
        extraInitialValues: initialExtraInitialValues,
        ignoredFields: initalIgnoredFields,
        validatePostalCode: initialValidatePostalCode,
        validationSource: initialValidationSource,
        validationOverrides: initialValidationOverrides,
        children
    }) => {

    const [allowedFields, setAllowedFields] = useState<string[]>([]);
    const [apiUrl] = useState<string>(initialApiURL);
    const [apiKey] = useState<string>(initialApiKey);
    const [countryChoices, setCountryChoices] = useState<ICountryChoices[]>([]);
    const [countryISOCode, setCountryISOCode] = useState<string>(initialCountryCode);
    const [countryRegionChoices, setCountryRegionChoices] = useState<IRegionChoices[]>([]);
    const [extraInitialValues, setExtraInitialValues] = useState<any>();
    const [customLabelOverrides] = useState<{ [country: string]: { [field: string]: string } } | undefined>(initialCustomLabelOverrides);
    const [labelOverrides, setLabelOverrides] = useState<{ [field: string]: string } | undefined>({});
    const [residualFormValues, setResidualFormValues] = useState<any>();
    const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema>();
    const [validationSource] = useState<string | undefined>(initialValidationSource);

    const handleCountryChange = (alphaTwoCode: string, residualValues?: FormikValues) => {
        setCountryISOCode(alphaTwoCode);
        if (residualValues) setResidualFormValues(residualValues);
    };

    useEffect(() => {
        try {
            getIsoCountryCodes(initialApiKey, initialApiURL).then((data: ICountryChoices[]) => {
                setCountryChoices(data);
            });
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => {
        try {
            getAllowedFields(initialApiKey, initialApiURL, countryISOCode, validationSource || null)
                .then((data: IGetAllowedFieldsResponse) => {
                    let revisedAllowedFields = initalIgnoredFields ? data.allowedFields.filter((field: string) => !initalIgnoredFields?.includes(field)) : data.allowedFields;
                    if (initialValidationOverrides?.["All"]) {
                        const overrideFields = Object.keys(initialValidationOverrides["All"]);
                        for (const field of overrideFields) {
                            if (!revisedAllowedFields.includes(field)) {
                                revisedAllowedFields.push(field);
                                revisedAllowedFields = sortAddressFields(revisedAllowedFields);
                            }
                        }
                    }
                    if (initialValidationOverrides?.[countryISOCode]) {
                        const overrideFields = Object.keys(initialValidationOverrides[countryISOCode]);
                        for (const field of overrideFields) {
                            if (!revisedAllowedFields.includes(field)) {
                                revisedAllowedFields.push(field);
                                revisedAllowedFields = sortAddressFields(revisedAllowedFields);
                            }
                        }
                    }
                    let validationData: any = data.validation;
                    const yupSchema: any = Object.keys(validationData).reduce<any>((obj, field: string) => {
                        let message: string = `${camelCaseToSentenceCase(data.labelOverrides?.[field] || field)} is required`;
                        let schema = Yup.string();
                        if (initialCustomErrorMessages && initialCustomErrorMessages.filter(e => e.field === field).length) {
                            message = initialCustomErrorMessages.filter(e => e.field === field)[0].errorMessage;
                        }
                        if (validationData[field] === "required") {
                            schema = schema.required(message);
                        }
                        if (field === "postalCode" && initialValidatePostalCode !== false) {
                            const regex = new RegExp(data.postalCodeRegex);
                            schema = schema.matches(regex, "Invalid Postal Code");
                        }
                        return { ...obj, [field]: schema }
                    }, {});
                    if (initialValidationOverrides?.["All"]) {
                        for (const fieldOverride of Object.keys(initialValidationOverrides?.["All"])) {
                            let message: string = `${camelCaseToSentenceCase(data.labelOverrides?.[fieldOverride] || fieldOverride)} is required`;
                            initialValidationOverrides?.["All"]?.[fieldOverride] === "required" ? yupSchema[fieldOverride] = Yup.string().required(message) : yupSchema[fieldOverride] = Yup.string().optional();
                        }
                    }
                    if (initialValidationOverrides?.[countryISOCode]) {
                        for (const fieldOverride of Object.keys(initialValidationOverrides?.[countryISOCode])) {
                            let message: string = `${camelCaseToSentenceCase(data.labelOverrides?.[fieldOverride] || fieldOverride)} is required`;
                            initialValidationOverrides?.[countryISOCode]?.[fieldOverride] === "required" ? yupSchema[fieldOverride] = Yup.string().required(message) : yupSchema[fieldOverride] = Yup.string().optional();
                        }
                    }
                    if (initialExtraInitialValues) setExtraInitialValues(initialExtraInitialValues);
                    setAllowedFields(revisedAllowedFields);
                    setLabelOverrides(data.labelOverrides);
                    setValidationSchema(Yup.object(yupSchema));
                    setCountryRegionChoices(data.regionChoices);
                });
        } catch (error) {
            throw error;
        }
    }, [countryISOCode, validationSource]);

    const initialValues = allowedFields.reduce((obj, field: string) => {
        let values = {};
        let defaultInitialValue = '';
        let defaultRegionValue = '';
        if (initialDefaultInitialValues && field in initialDefaultInitialValues) {
            if (field === "region" && countryRegionChoices.filter(c => c.shortCode === initialDefaultInitialValues[field]).length) {
                defaultRegionValue = initialDefaultInitialValues[field];
            }
            defaultInitialValue = initialDefaultInitialValues[field];
        }
        switch (field) {
            case "isoCountryCode":
                values = { ...values, isoCountryCode: countryISOCode };
                break;
            case "region":
                values = { ...values, ...(allowedFields.includes('region') && countryRegionChoices.length && { region: defaultRegionValue || countryRegionChoices![0].shortCode }) };
                break;
            default:
                const finalFormValue = residualFormValues && residualFormValues[field] ? residualFormValues[field] : defaultInitialValue;
                values = { ...values, ...(allowedFields.includes(field) && { [field]: finalFormValue }) };
                break;
        }
        if (extraInitialValues) {
            for (const field of Object.keys(extraInitialValues)) {
                const finalFormValue = residualFormValues && residualFormValues[field] ? residualFormValues[field] : extraInitialValues[field];
                values[field] = finalFormValue || extraInitialValues[field];
            }
        }
        return { ...obj, ...values };
    }, {});

    return <AddressFormContext.Provider
        value={{
            allowedFields,
            apiKey,
            apiUrl,
            countryChoices,
            countryISOCode,
            countryRegionChoices,
            customLabelOverrides,
            initialValues,
            labelOverrides,
            validationSchema,
            handleCountryChange
        }}
    >
        {children}
    </AddressFormContext.Provider>
};
const useAddressFormContext = (): IAddressFormContext => useContext(AddressFormContext);

export default useAddressFormContext;

export { AddressFormProvider, AddressFormContext };