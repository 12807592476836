import React from 'react';
import {
    IGroupUser,
    IIdentifyUser,
    IPageView,
    ITrackUserAction,
    IUserAlias,
} from './analytics-interface';
import { initialSegmentState, ISegmentState } from './analytics-state';
export interface ISegmentContext extends ISegmentState {
    analytics: {
        /**
         * ```js
         * payload: {
         * // The database ID for the user. If you don't know who the user is yet, you can omit the `userId` and just record `traits`.
         * userId?: string;
         *
         *
         * // A dictionary of traits you know about the user, like their `email` or `name`.
         * traits?: IIdentifyTrait | {};
         *
         *
         * // A dictionary of options
         * options?: IOptions;
         *
         *
         * // A function executed after a short timeout, giving the browser time to make outbound requests first.
         * callback?: () => void;
         * }
         * ```
         *
         * Use the `identifyUser` method to send data of who the user is.
         */
        identifyUser: (payload: IIdentifyUser) => void;
        /**
         * ```js
         * payload: {
         * // The name of the event you're tracking. Example: "User Registered".
         * event: string;
         *
         *
         * // A dictionary of properties for the event
         * properties?: IProperties;
         *
         *
         * // A dictionary of options
         * options?: IOptions;
         *
         *
         * // A function executed after a short timeout, giving the browser time to make outbound requests first.
         * callback?: () => void;
         * }
         * ```
         *
         * Use the `trackUserAction` method to send data of what the user is doing.
         */
        trackUserAction: (payload: ITrackUserAction) => void;
        /**
         * ```js
         * payload: {
         * // The category of the page. Useful for cases where many pages might live under a single category.
         * category?: string;
         *
         *
         * // The name of the page
         * name?: string;
         *
         *
         * // A dictionary of properties of the page.
         * properties?: IProperties;
         *
         *
         * // A dictionary of options
         * options?: IOptions;
         *
         *
         * // A function executed after a short timeout, giving the browser time to make outbound requests first.
         * callback?: () => void;
         * }
         * ```
         *
         * Use the `pageView` method to send data of what page they are on..
         */
        pageView: (payload: IPageView) => void;
        /**
         * ```js
         * payload: {
         * // The Group ID to associate with the current user
         * groupId: string;
         *
         *
         * // A dictionary of traits for the group. Example traits for a group include `address`, `website` * // and `employees`
         * name?: string;
         *
         *
         * // A dictionary of properties of the page.
         * properties?: IProperties;
         *
         *
         * // A dictionary of options
         * options?: IOptions;
         *
         *
         * // A function executed after a short timeout, giving the browser time to make outbound requests first.
         * callback?: () => void;
         * }
         * ```
         *
         * Use the `groupUser` method to send data of what page they are on..
         */
        groupUser: (payload: IGroupUser) => void;
        /**
         * ```js
         * payload: {
         * // Previous unique identifier for the user.
         * previousId: string;
         *
         * // Unique identifier for the user in your database or that will be the user's new identity or an * // existing identity that you wish to merge with the `previousId`
         * userId: string;
         *
         * }
         * ```
         *
         * Use the `userAlias` method to send data about a user's past identity..
         */
        userAlias: (payload: IUserAlias) => void;
    };
}

const initialContext = {
    ...initialSegmentState,
    analytics: {
        identifyUser: () => {},
        trackUserAction: () => {},
        pageView: () => {},
        groupUser: () => {},
        userAlias: () => {},
    },
};

const SegmentContext = React.createContext<ISegmentContext>(initialContext);

export default SegmentContext;
