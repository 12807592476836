import React from 'react';
import styled from 'styled-components';
import {black, white} from '../constants';

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
    &:hover,
    &:focus {
        & > span:last-child {
            visibility: visible;
        }
    }
`;
const TooltipText = styled.span`
    visibility: hidden;
    min-width: 150px;
    background-color: ${black};
    color: ${white};
    text-align: center;
    border-radius: 2px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
`;

export interface TooltipProps {
    text: string;
}

export const Tooltip: React.FC<TooltipProps> = (props) => {
    const {text, children} = props;
    return (
        <TooltipWrapper>
            {children}
            <TooltipText>{text}</TooltipText>
        </TooltipWrapper>
    );
};
