import axios from 'axios';
import { discountFactory } from '../utilities/factories/discount.factory';
import { IDiscount, IResponseDiscount } from '../interfaces/discounts/discount.interface';

const getOrganisationDiscounts = async (chellApiUrl: string, chellApiKey: string, accessToken: string, organisation: string): Promise<IDiscount[]> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': chellApiKey,
    };

    const response = await axios.get<IResponseDiscount[]>(
        `${chellApiUrl}/organisations/${organisation}/discounts`,
        {
            headers,
        }
    );

    return discountFactory(response.data);
};

export default getOrganisationDiscounts;
