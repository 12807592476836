import axios from 'axios';
import IUserRbacResponse from '../interfaces/user/user-rbac-response.interface';

const getRbacByOrganisation = async (chellApiUrl: string, chellApiKey: string, accessToken: string, organisation: string): Promise<IUserRbacResponse> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': chellApiKey,
    };

    const response = await axios.get<IUserRbacResponse>(`${chellApiUrl}/rbac`, { headers });

    return response.data;
};

export default getRbacByOrganisation;
