import React, { FC } from 'react';
import { Field, ErrorMessage } from "formik";
import useTheme from '../../context/theme-provider/theme-provider';
import { camelCaseToSentenceCase } from '../../../utilities/string-functions';
import { IRegionChoices } from '../../../interfaces/IRegionChoices';
import { IRegionSelectProps } from '../../../interfaces/field-interfaces/IRegionSelectProps';

const RegionSelectField: FC<IRegionSelectProps> = ({ alternateLabel, regions }: IRegionSelectProps) => {

    const { theme } = useTheme();

    return (
        <div className={`${theme.inputBox} ${theme.regionsInputBox}`}>
            <label htmlFor={'region'} className={`${theme.label} ${theme.regionsLabel}`}>{camelCaseToSentenceCase(alternateLabel || "Region")}</label>
            <Field id={'region'} className={`${theme.inputFormField} ${theme.regionsDropDownSelect}`} name="region" as="select">
                {regions.map((option: IRegionChoices) => {
                    return <option key={option.name} className={theme.regionsOptions} value={option.shortCode}>{option.name}</option>
                })}
            </Field>
            <div className={`${theme.errorMessageBox} ${theme.regionsErrorMessageBox}`} >
                <ErrorMessage name={'region'} className={`${theme.errorMessage} ${theme.regionsErrorMessage}`} />
            </div>
        </div>
    );
};

export default RegionSelectField;