import React, {ButtonHTMLAttributes, Fragment} from 'react';
import styled from 'styled-components';
import {primaryAccent, white, errorRed, boxShadowSml, lightGrey, black} from '../constants';
import {Spinner} from '../icons/spinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    block?: boolean;
    danger?: boolean;
    borderOnHover?: boolean;
    ghost?: boolean;
    size?: 'small' | 'medium' | 'large';
    loading?: boolean;
    buttonType?: 'primary' | 'text' | 'danger' | 'secondary';
}

export const StyledButton = styled.button<ButtonProps>`
    height: 39px;
    border-radius: 20px;
    white-space: nowrap;
    color: ${(p) => {
        if (p.danger) return `${errorRed}`;
        if (p.ghost) return `${black}`;
        return `${white}`;
    }};
    padding: 0 20px;
    outline: none;
    transition: box-shadow 240ms ease-in;
    width: ${(p) => (p.block ? '100%' : 'auto')};
    > * {
        display: inline-block;
    }
    pointer-events: ${(props) => (props.loading ? `none` : `auto`)};
    &:hover,
    &:focus {
        cursor: pointer;
        box-shadow: ${boxShadowSml};
        transition: box-shadow 240ms ease-out;
    }
    &:disabled {
        cursor: no-drop;
        background-color: ${lightGrey};
        border-color: lightGrey;
        color: ${primaryAccent};
        box-shadow: none;
        border: 1px solid ${lightGrey};
    }
`;

const StyledSpinner = styled.span`
    vertical-align: bottom;
    margin-right: 10px;
`;

export const ButtonContent: React.FC<{color?: string; loading: boolean}> = (props) => {
    const {loading = false} = props;
    return (
        <Fragment>
            {loading && (
                <StyledSpinner>
                    <Spinner color={props.color} />
                </StyledSpinner>
            )}
            <span>{props.children}</span>
        </Fragment>
    );
};
