import React from 'react';
import styled from 'styled-components';
import {black, primaryColorLight} from '../constants';

const StyledLinks = styled.ul<NavLinkWrapperProps>`
    text-align: ${(props) => props.align};
    list-style-type: none;
    & > a {
        text-decoration: none;
    }
`;

interface NavLinkProps {
    isActive?: boolean;
}
export const NavLink = styled.li<NavLinkProps>`
    color: ${(props) => (props.isActive ? `${primaryColorLight}` : `${black}`)};
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    display: inline;
    padding: 8px 16px;
    & > a {
        text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        color: ${primaryColorLight};
    }
`;

interface NavLinkWrapperProps {
    align?: 'left' | 'right';
}
export const NavLinkWrapper: React.FC<NavLinkWrapperProps> = (props) => {
    const {children, align = 'right'} = props;
    return (
        <nav style={{float: align}}>
            <StyledLinks align={align}>{children}</StyledLinks>
        </nav>
    );
};

export const SiteLogoWrapper = styled.div<{padding?: string}>`
    float: left;
    padding: ${(props) => (props.padding ? props.padding : '16px 0')};
    vertical-align: middle;
    display: table-cell;
    > * {
        display: inline-block;
    }
`;
