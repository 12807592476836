import React, { FC } from 'react';
import { cypressAuth0withAuthenticationRequired } from '@adaptavist-commerce/auth0-with-cypress-react';
import { Route } from 'react-router-dom';
import AuthenticatedUserRouteProps from '../../interfaces/routes/authenticated-user-route-props.interface';

const AuthenticatedUserRoute: FC<AuthenticatedUserRouteProps> = ({ component, LoadingComponent, ...args }) => {
    const componentWithAuthenticationRequired = cypressAuth0withAuthenticationRequired(component, {
        onRedirecting: () => <LoadingComponent />,
    });

    return <Route component={componentWithAuthenticationRequired} {...args} />;
};
export default AuthenticatedUserRoute;
