import React, {useState, useRef, useEffect} from 'react';
import {Spinner} from '../icons/spinner';
import {ReactComponent as DropdownIcon} from '../icons/dropdown.svg';
import styled from 'styled-components';
import {InputWrapper, InputWrapperGroup, StyledInput} from './input';
import {graySecondaryLightColor, offWhite, white} from '../constants';

const StyledIconBox = styled.button`
    height: 39px;
    padding: 5px 15px;
    background: transparent;
    border: none;
    position: absolute;
    right: 5px;
    outline: none;
`;

const DropdownWrapper = styled.div`
    width: 100%;
    z-index: 1000;
    position: relative;
`;

const DropdownOptions = styled.div`
    position: absolute;
    top: 10px;
    padding: 14px 0;
    background-color: ${white};
    box-shadow: 0 1.5px 6px ${graySecondaryLightColor};
    width: 100%;
    & > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;
const DropdownOption = styled.li`
    height: 39px;
    width: 100%;
    padding: 10px 14px;
    &:hover,
    &:focus {
        cursor: pointer;
        background-color: ${offWhite};
    }
`;

type OptionType = {
    key: string | number;
    text: string;
};
interface AutoCompleteI extends React.HtmlHTMLAttributes<HTMLInputElement> {
    loading?: boolean;
    options?: OptionType[];
    value?: string;
    onOptionSelect?: (option: OptionType) => void;
    onSearch?: (value: string) => void;
}

export const AutoComplete: React.FC<AutoCompleteI> = (props) => {
    const node = useRef<any>();
    const [open, setOpen] = useState(false);
    const {loading, onOptionSelect, onSearch, options, ...moreProps} = props;

    const handleClick = (event: any) => {
        if (node.current.contains(event.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    const handleOptionSelect = (option: OptionType) => {
        if (onOptionSelect) {
            onOptionSelect(option);
            setOpen(false);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        if (onSearch) onSearch(value);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <div ref={node}>
            <InputWrapperGroup>
                <InputWrapper>
                    <StyledInput
                        onClick={() => {
                            console.log('Clicking');
                            setOpen(!open);
                        }}
                        onChange={handleChange}
                        value=""
                        type="text"
                        {...moreProps}
                    />
                </InputWrapper>
                <StyledIconBox>
                    {loading && <Spinner />}
                    {!loading && <DropdownIcon />}
                </StyledIconBox>
            </InputWrapperGroup>
            {options?.length !== 0 && options !== undefined && open && (
                <DropdownWrapper>
                    <DropdownOptions>
                        <ul>
                            {options.map((option) => (
                                <DropdownOption onClick={() => handleOptionSelect(option)} key={option.key}>
                                    {option.text}
                                </DropdownOption>
                            ))}
                        </ul>
                    </DropdownOptions>
                </DropdownWrapper>
            )}
        </div>
    );
};
