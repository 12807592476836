import styled from 'styled-components';
import {boxShadowLrg, graySecondaryLightColor, offWhite, spacingThree, spacingTwo} from '../constants';

export const Container = styled.div`
    max-width: 1240px;
    margin: 0 auto;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

interface ColumnProps {
    span?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xLrgSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    lrgSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    medSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    smlSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xSmlSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const Column = styled.div<ColumnProps>`
    padding: 0 ${spacingTwo} ${spacingThree};
    & &:first-of-type {
        padding-left: 0;
    }
    & &:last-of-type {
        padding-right: 0;
    }
    width: ${(p) => (p.xSmlSpan ? (p.xSmlSpan / 12) * 100 + '%' : p.span ? (p.span / 12) * 100 + '%' : '100%')};
    @media screen and (min-width: 480px) {
        width: ${(p) => (p.smlSpan ? (p.smlSpan / 12) * 100 + '%' : p.span ? (p.span / 12) * 100 + '%' : '100%')};
    }
    @media screen and (min-width: 1024px) {
        width: ${(p) => (p.medSpan ? (p.medSpan / 12) * 100 + '%' : p.span ? (p.span / 12) * 100 + '%' : '100%')};
    }
    @media screen and (min-width: 1240px) {
        width: ${(p) => (p.lrgSpan ? (p.lrgSpan / 12) * 100 + '%' : p.span ? (p.span / 12) * 100 + '%' : '100%')};
    }
    @media screen and (min-width: 1600px) {
        width: ${(p) => (p.xLrgSpan ? (p.xLrgSpan / 12) * 100 + '%' : p.span ? (p.span / 12) * 100 + '%' : '100%')};
    }
`;

const StyledPanel = styled.div<{borderRadius?: number}>`
    padding: ${spacingThree};
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : '10px')};
`;

export const PanelDropShadow = styled(StyledPanel)`
    box-shadow: ${boxShadowLrg};
`;
export const PanelDropShadowLeft = styled(StyledPanel)`
    box-shadow: -3px 0 6px ${graySecondaryLightColor};
`;

export const PanelWithBorder = styled(StyledPanel)`
    border: 1px solid ${graySecondaryLightColor};
`;

export const PanelHeader = styled.div<{paddingBottom?: number}>`
    display: flex;
    & h2,
    h1,
    h3,
    h4,
    h5,
    h6,
    p {
        padding: 0 0 ${(props) => `${props.paddingBottom}px` || '1rem'};
    }
`;

export const PanelBody = styled.div`
    display: block;
`;

export const Rule = styled.hr`
    width: 100%;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${offWhite};
    border: none;
    margin-bottom: ${spacingThree};
`;
