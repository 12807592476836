import React, {Fragment} from 'react';
import styled from 'styled-components';
import {InputWrapperGroup, InputWrapper, StyledInput, ErrorMessageBox} from './input';
import {offWhite, fontSizeOne, lightGrey, secondaryLight, white, boxShadowSml, disableGrey} from '../constants';
import {ReactComponent as SearchIcon} from '../icons/search.svg';
import {Spinner} from '../icons/spinner';

const SearchAddonAfter = styled.button`
    height: 39px;
    padding: 5px 15px;
    color: ${white};
    font-weight: 400;
    font-size: ${fontSizeOne};
    text-align: left;
    background-color: ${secondaryLight};
    border: 1px solid ${secondaryLight};
    border-radius: 0 4px 4px 0;
    outline: none;
    &:hover {
        box-shadow: ${boxShadowSml};
        transition: box-shadow 240ms ease-out;
    }
    &:disabled {
        background-color: ${lightGrey};
        color: ${offWhite};
        color: ${disableGrey};
        box-shadow: none;
        border: 2px solid ${offWhite};
        cursor: not-allowed;
    }
`;

// Search
export interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
    loading?: boolean;
    enterButton?: string | React.ReactNode;
    width?: number;
    errorMessage?: string;
}

export const Search = (props: SearchProps) => {
    const {loading, enterButton, errorMessage, ...moreProps} = props;
    return (
        <Fragment>
            <InputWrapperGroup>
                <InputWrapper>
                    <StyledInput type="search" allowClear={false} addonAfter={true} {...moreProps} />
                </InputWrapper>
                <SearchAddonAfter disabled={loading || moreProps.disabled}>
                    {loading && <Spinner />}
                    {!loading && enterButton}
                    {!loading && !enterButton && <SearchIcon />}
                </SearchAddonAfter>
            </InputWrapperGroup>
            <ErrorMessageBox>
                <p>{errorMessage}</p>
            </ErrorMessageBox>
        </Fragment>
    );
};
