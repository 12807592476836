import styled from 'styled-components';
import {white} from '../constants';

export const Spinner = styled.div<{color?: string}>`
    border-radius: 50%;
    border-top: 2px solid ${(props) => (props.color ? `${props.color}` : `${white}`)};
    width: 20px;
    height: 20px;
    -webkit-animation: spin 0.8s linear infinite; /* Safari */
    animation: spin 0.8s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
