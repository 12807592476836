import axios from 'axios';
import IOrganisation from '../interfaces/organisation/organisation.interface';

const getOrganisations = async (chellApiUrl: string, chellApiKey: string, accessToken: string, organisation: string): Promise<IOrganisation[]> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': chellApiKey,
    };

    const response = await axios.get<IOrganisation[]>(`${chellApiUrl}/organisations`, { headers });

    return response.data;
};

export default getOrganisations;
