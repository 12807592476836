import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react'
import { useContext } from 'react'
import Auth0WithCypressContext, { IAuth0WithCypress } from './auth0-cypress-context'

/**
 * ```js
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   // Auth methods:
 *   getAccessTokenSilently,
 *   getAccessTokenWithPopup,
 *   getIdTokenClaims,
 *   loginWithRedirect,
 *   loginWithPopup,
 *   logout,
 * } = useAuth0WithCypress();
 * ```
 *
 * Use the `useAuth0WithCypress` hook in your components to access the auth state and methods.
 */
const useAuth0WithCypress = (): IAuth0WithCypress | Auth0ContextInterface =>
    // @ts-ignore
    useContext(window.Cypress ? Auth0WithCypressContext : Auth0Context)

export default useAuth0WithCypress
