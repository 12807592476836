import React, { FC, useContext, useState } from 'react';
import styles from '../../../styles.module.css';
import { IThemeProvider } from '../../../interfaces/IThemeProvider';

interface IThemeContext {
    theme: any;
};

const ThemeContext = React.createContext<IThemeContext>({ theme: styles });

const ThemeProvider: FC<IThemeProvider> = (
    {
        theme: themeOverrides,
        children
    }) => {
    const [theme] = useState<any>({ ...styles, ...themeOverrides });

    return <ThemeContext.Provider value={{ theme }}>
        {children}
    </ThemeContext.Provider>
};

const useTheme = (): IThemeContext => useContext(ThemeContext);

export default useTheme;

export { ThemeProvider, ThemeContext };