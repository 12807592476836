import React, { FC } from 'react';
import { withAuthenticationRequired } from '@adaptavist-commerce/auth0-with-cypress-react';
import { Route } from 'react-router-dom';
import withAuthorizationRoute from './with-authorization-route';
import useOrganisation from '../context/organisation-context';
import { AuthorizedRouteProps } from '../../interfaces/routes/authorized-route-props.interface';

const AuthorizedUserRoute: FC<AuthorizedRouteProps> = (props) => {
    const organisation = useOrganisation();
    const { component, LoadingComponent, allowedPermissions, unauthorizedComponent, ...args } = props;
    if (!organisation.isLoaded) {
        return <Route component={LoadingComponent} {...args} />;
    }
    const componentWithAuthorizationRequired = withAuthorizationRoute(component, organisation, allowedPermissions, unauthorizedComponent);
    const componentWithAuthenticationRequired = withAuthenticationRequired(componentWithAuthorizationRequired, {
        onRedirecting: () => (
            <LoadingComponent>
                <h1>Redirecting for authentication...</h1>
            </LoadingComponent>
        ),
    });

    return <Route component={componentWithAuthenticationRequired} {...args} />;
};

export default AuthorizedUserRoute;
