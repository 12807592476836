import React, { FC, useEffect, useState } from 'react';
import SegmentContext from './analytics-context';
import {
    IIdentifyUser,
    ITrackUserAction,
    IPageView,
    IGroupUser,
    IUserAlias,
} from './analytics-interface';
import { initialSegmentState, ISegmentState } from './analytics-state';
import { AnalyticsMethods } from './constants';

export interface IAnalyticProviderOptions {
    /**
     * Your API key to send data to the source
     */
    analyticKey: string;
    /**
     * The environment your app is currently operating on. Example "development", "staging"
     */
    currentEnvironment: string;
    /**
     * Environments where you want your analytics to be executed
     */
    allowedEnvironments: string[];
}

const AnalyticsProvider: FC<IAnalyticProviderOptions> = (props) => {
    const { analyticKey, currentEnvironment, allowedEnvironments, children } = props;
    const [analytics, setAnalytics] = useState<any>();

    const [providerState, setProviderState] = useState<ISegmentState>(initialSegmentState);

    useEffect(() => {
        setProviderState((prevState) => ({
            ...prevState,
            isInitializing: true,
        }));
        if (!allowedEnvironments.includes(currentEnvironment)) {
            setProviderState((prevState) => ({
                ...prevState,
                isAnalyticsInitialized: false,
                isInitializing: false,
            }));
            return;
        }
        (function () {
            // @ts-ignore
            const analytics = (window.analytics = window.analytics || []);
            if (!analytics.initialize) {
                if (analytics.invoked) {
                    window.console &&
                        console.error &&
                        console.error('Segment snippet included twice.');
                    setProviderState((prevState) => ({
                        ...prevState,
                        isInitializing: false,
                        isAnalyticsInitialized: true,
                    }));
                    return;
                }
                analytics.invoked = !0;
                analytics.methods = AnalyticsMethods;
                analytics.factory = function (t: any) {
                    return function () {
                        const e = Array.prototype.slice.call(arguments);
                        e.unshift(t);
                        analytics.push(e);
                        return analytics;
                    };
                };
                for (let t = 0; t < analytics.methods.length; t++) {
                    const e = analytics.methods[t];
                    analytics[e] = analytics.factory(e);
                }
                analytics.load = function (t: any, e: any) {
                    const n = document.createElement('script');
                    n.type = 'text/javascript';
                    n.async = !0;
                    n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
                    const a = document.getElementsByTagName('script')[0];
                    a.parentNode!.insertBefore(n, a);
                    analytics._loadOptions = e;
                    setProviderState((prevState) => ({
                        ...prevState,
                        isAnalyticsInitialized: true,
                        isInitializing: false,
                    }));
                };
                analytics.SNIPPET_VERSION = '4.1.0';
                analytics.load(analyticKey);
                setAnalytics(analytics);
                setProviderState((prevState) => ({
                    ...prevState,
                    isInitializing: false,
                    isAnalyticsInitialized: true,
                }));
            }
        })();
    }, [allowedEnvironments, currentEnvironment, analyticKey]);

    const identifyUser = (payload: IIdentifyUser) => {
        if (!analytics) return;
        // @ts-ignore
        window.analytics.identify(
            payload.userId,
            payload.traits,
            payload.options,
            payload.callback,
        );
    };
    const trackUserAction = (payload: ITrackUserAction) => {
        if (!analytics) return;
        // @ts-ignore
        window.analytics.track(
            payload.event,
            payload.properties,
            payload.options,
            payload.callback,
        );
    };
    const pageView = (payload: IPageView) => {
        if (!analytics) return;
        // @ts-ignore
        window.analytics.page(
            payload.category,
            payload.name,
            payload.properties,
            payload.options,
            payload.callback,
        );
    };
    const groupUser = (payload: IGroupUser) => {
        if (!analytics) return;
        // @ts-ignore
        window.analytics.group(payload.groupId, payload.traits, payload.options, payload.callback);
    };
    const userAlias = (payload: IUserAlias) => {
        if (!analytics) return;
        // @ts-ignore
        window.analytics.alias(payload.previousId, payload.userId);
    };

    return (
        <SegmentContext.Provider
            value={{
                ...providerState,
                analytics: {
                    identifyUser,
                    trackUserAction,
                    pageView,
                    groupUser,
                    userAlias,
                },
            }}
        >
            {children}
        </SegmentContext.Provider>
    );
};

export default AnalyticsProvider;
