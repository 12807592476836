import React from 'react';
import styled from 'styled-components';
import {black, grayPrimaryLightColor, secondaryLight, white} from '../constants';
import {ReactComponent as CirclePlus} from '../icons/circle-plus.svg';
import {ReactComponent as CircleClose} from '../icons/circle-close.svg';

const CheckboxContainer = styled.label<{checked: boolean}>`
    position: relative;
    display: inline-block;
    line-height: 38px;
    padding: 0 14px;
    border-radius: 24px;
    height: 39px;
    background-color: ${(props) => (props.checked ? `${secondaryLight}` : `${white}`)};
    color: ${(props) => (props.checked ? `${white}` : `${black}`)};
    border: 1px solid ${(props) => (props.checked ? `${secondaryLight}` : `${grayPrimaryLightColor}`)};
    cursor: pointer;
    transition: all 400ms;
    &:hover,
    &:focus {
        border-color: ${secondaryLight};
    }
`;

const StyledCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const IconContainer = styled.span`
    margin-left: 8px;
    vertical-align: -11%;
`;

interface CheckboxProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
    checked?: boolean;
    showIcon?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
    const {checked = false, showIcon = true, children, ...moreProps} = props;
    return (
        <div style={{display: 'inline-block'}}>
            <CheckboxContainer checked={checked}>
                {children}
                <StyledCheckbox checked={checked} type="checkbox" {...moreProps} />
                {showIcon && (
                    <IconContainer>
                        {!checked && <CirclePlus />}
                        {checked && <CircleClose />}
                    </IconContainer>
                )}
            </CheckboxContainer>
        </div>
    );
};
