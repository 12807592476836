import styled from 'styled-components';
import {fontSizeOne, fontSizeThree, fontSizeTwo, offWhite, spacingThree, titleFontFamily} from '../constants';

export const PageTitle = styled.h1`
    font-size: ${fontSizeThree};
    font-family: ${titleFontFamily};
    margin-bottom: ${spacingThree};
`;

export const SectionTitle = styled.h2`
    font-size: ${fontSizeTwo};
    font-family: ${titleFontFamily};
    margin-bottom: ${spacingThree};
`;

export const ContentTitle = styled.h3`
    font-size: ${fontSizeOne};
    font-family: ${titleFontFamily};
    margin-bottom: ${spacingThree};
`;

export const PanelTitle = styled.h2`
    font-size: ${fontSizeOne};
    font-family: ${titleFontFamily};
    padding-bottom: ${spacingThree};
    margin: 0 auto ${spacingThree};
    border-bottom: 2px solid ${offWhite};
    justify-items: center;
`;
