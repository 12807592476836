import { useContext } from 'react';
import SegmentContext, { ISegmentContext } from './analytics-context';

/**
 * ```js
 * const {
 *   // Analytics state:
 *   isAnalyticsInitialized,
 *   isInitializing,
 *   // Analytics methods:
 *   analytics,
 * } = useSegment();
 * ```
 *
 * Use the `useSegment` hook in your components to access the analytics state and methods.
 */
const useSegment = (): ISegmentContext => useContext(SegmentContext);

export default useSegment;
