import React from 'react';
import styled from 'styled-components';
import {white, black, secondaryAccent} from '../constants';
import {ButtonContent, ButtonProps, StyledButton} from './styled-button';

// SECONDARY
const StyledSecondaryButton = styled(StyledButton)`
    border: 1px solid ${secondaryAccent};
    background-color: ${secondaryAccent};
`;
// SECONDARY GHOST
const SecondaryButtonGhost = styled(StyledButton)`
    border: 1px solid ${black};
    background-color: ${white};
    &:hover,
    &:focus {
        border: 1px solid ${secondaryAccent};
        background-color: ${secondaryAccent};
        color: ${white};
    }
`;

export const SecondaryButton: React.FC<ButtonProps> = (props) => {
    const {children, loading = false, disabled = false, ...otherProps} = props;
    if (props.ghost)
        return (
            <SecondaryButtonGhost {...otherProps} disabled={disabled}>
                <ButtonContent loading={loading} color={black}>
                    {children}
                </ButtonContent>
            </SecondaryButtonGhost>
        );
    return (
        <StyledSecondaryButton {...otherProps} disabled={disabled}>
            <ButtonContent loading={loading}>{children}</ButtonContent>
        </StyledSecondaryButton>
    );
};
