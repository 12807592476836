import { Auth0ContextInterface, GetIdTokenClaimsOptions, IdToken } from '@auth0/auth0-react'
import { createContext } from 'react'
import { initialAuthState } from './auth-state'
import { IAuth0Claims } from './interfaces'

export interface IAuth0WithCypress extends Omit<Auth0ContextInterface, 'getIdTokenClaims'> {
    getIdTokenClaims: (
        options?: GetIdTokenClaimsOptions | GetIdTokenClaimsOptions,
    ) => Promise<IAuth0Claims | IdToken>
}

/**
 * @ignore
 */
const stub = (): never => {
    throw new Error('You forgot to wrap your component in <Auth0Provider>.')
}

/**
 * @ignore
 */
const initialContext = {
    ...initialAuthState,
    buildAuthorizeUrl: stub,
    buildLogoutUrl: stub,
    getAccessTokenSilently: stub,
    getAccessTokenWithPopup: stub,
    getIdTokenClaims: stub,
    loginWithRedirect: stub,
    loginWithPopup: stub,
    logout: stub,
}

/**
 * The Auth0 Context
 */
const Auth0WithCypressContext = createContext<IAuth0WithCypress>(initialContext)

export default Auth0WithCypressContext
