import styled from 'styled-components';
import {lightGrey} from '../constants';

export const Info = styled.span`
    background-color: ${lightGrey};
    display: inline-flex;
    align-items: center;
    height: 30px;
    border-radius: 14px;
    padding: 0 14px;
`;

interface TagStatusProps {
    color: 'yellow' | 'green';
}

export const Status = styled.span<TagStatusProps>`
    background-color: ${(p) => {
        switch (p.color) {
            case 'yellow':
                return '#FFE58F';
            case 'green':
                return '#B7EB8F';
        }
    }};
    border: ${(p) => {
        switch (p.color) {
            case 'yellow':
                return '1px solid #FAAD14';
            case 'green':
                return '1px solid #52C41A';
        }
    }};
    display: inline-flex;
    align-items: center;
    height: 30px;
    border-radius: 3px;
    padding: 0 14px;
`;
