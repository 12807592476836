import axios from 'axios';

const getIsoCountryCodes = async (apiKey: string, apiURL: string) => {
    
    const headers = { "x-api-key": apiKey };
    
    const res = await axios.get(`${apiURL}/iso-country-codes`, {headers});
    
    return res.data;
    
};

export default getIsoCountryCodes;
