import React from 'react';
import styled from 'styled-components';
import {black, disableGrey, graySecondaryLightColor, lightGrey, secondaryColorLight, white} from '../constants';
import {Tooltip} from '../index';
import {ReactComponent as MoreIcon} from '../icons/more.svg';
import {ReactComponent as NextIcon} from '../icons/skip.svg';
import {ReactComponent as PreviousIcon} from '../icons/previous.svg';

export const PaginationWrapper = styled.div`
    display: inline-block;
    & button:not(:last-child) {
        margin: 0 10px;
    }
    & button:last-child {
        margin-left: 10px;
    }
`;

export const PaginationButton = styled.button<{isActive?: boolean}>`
    height: 30px;
    padding: 0 12px;
    color: ${(props) => (props.isActive ? `${white}` : `${black}`)};
    background-color: ${(props) => (props.isActive ? `${secondaryColorLight}` : `${graySecondaryLightColor}`)};
    border: 1px solid ${(props) => (props.isActive ? `${secondaryColorLight}` : `${graySecondaryLightColor}`)};
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    &:hover,
    &:focus {
        color: ${white};
        background-color: ${secondaryColorLight};
        border-color: ${secondaryColorLight};
    }
    &:disabled {
        background-color: ${lightGrey};
        color: ${disableGrey};
        border-color: ${lightGrey};
        cursor: not-allowed;
    }
`;

const StyledIconWrapper = styled.span`
    height: 30px;
    margin: 0 10px;
    cursor: pointer;
    vertical-align: middle;
    transition: all 400ms;
    & svg:first-child {
        display: inline-block;
    }
    & svg:nth-child(2) {
        display: none;
    }
    &:hover,
    &:focus {
        & svg:first-child {
            display: none;
        }
        & svg:nth-child(2) {
            display: inline-block;
        }
    }
`;

interface PaginationI {
    defaultCurrent?: number;
    onChange?: (page: number, pageSize?: number) => void;
    totalPage?: number;
    defaultPageSize?: number;
}

interface IconWrapperProps {
    onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

const Previous5PagesIconWrapper = (props: IconWrapperProps) => (
    <Tooltip text="Previous 5 pages">
        <StyledIconWrapper {...props}>
            <MoreIcon />
            <PreviousIcon />
        </StyledIconWrapper>
    </Tooltip>
);

const Next5PagesIconWrapper = (props: IconWrapperProps) => (
    <Tooltip text="Next 5 pages">
        <StyledIconWrapper {...props}>
            <MoreIcon />
            <NextIcon />
        </StyledIconWrapper>
    </Tooltip>
);

export const Pagination = (props: PaginationI) => {
    const {onChange, defaultCurrent = 1, defaultPageSize = 10, totalPage = 1} = props;
    const totalPagination = totalPage < 10 ? 1 : Math.ceil(totalPage / defaultPageSize);
    const [paginationState, setPaginationState] = React.useState({
        currentLocation: defaultCurrent,
    });
    // Return page and page size number to on change function
    const handleOnChange = (page: number, pageSize?: number) => {
        if (onChange) {
            onChange(page, pageSize);
        }
    };
    // Function for other buttons in the pagination
    const handleChange = (pageNumber: number) => {
        let newPageNumber = pageNumber;
        if (newPageNumber > totalPagination) {
            newPageNumber = totalPagination;
        } else if (newPageNumber < 1) newPageNumber = 1;
        setPaginationState({
            ...paginationState,
            currentLocation: newPageNumber,
        });
        handleOnChange(newPageNumber, defaultCurrent);
    };

    const {currentLocation} = paginationState;

    // Start the pagination list
    let startSlice = currentLocation < 5 ? 0 : currentLocation - 2;
    startSlice = totalPagination - currentLocation <= 3 ? totalPagination - 5 : startSlice;

    // End the pagination list
    let endSlice = currentLocation > 4 ? currentLocation + 1 : 5;
    endSlice = totalPagination - currentLocation <= 3 ? totalPagination : endSlice;

    const showMoreAndPageEnd = totalPagination - currentLocation >= 4 && totalPagination !== 5;
    const showMoreAndPageStart = currentLocation >= 5 && totalPagination !== 5;
    return (
        <PaginationWrapper>
            <PaginationButton onClick={() => handleChange(currentLocation - 1)} disabled={currentLocation === 1}>
                Prev
            </PaginationButton>
            {showMoreAndPageStart && <PaginationButton onClick={() => handleChange(1)}>1</PaginationButton>}
            {showMoreAndPageStart && <Previous5PagesIconWrapper onClick={() => handleChange(currentLocation - 5)} />}
            {[...Array.from(Array(totalPagination).keys())].slice(startSlice, endSlice).map((index: number) => {
                const pageNumber = index + 1;
                return (
                    <PaginationButton
                        key={`pagination-${index}`}
                        isActive={currentLocation === pageNumber}
                        onClick={() => handleChange(pageNumber)}
                    >
                        {pageNumber}
                    </PaginationButton>
                );
            })}
            {showMoreAndPageEnd && <Next5PagesIconWrapper onClick={() => handleChange(currentLocation + 5)} />}
            {showMoreAndPageEnd && (
                <PaginationButton onClick={() => handleChange(totalPagination)}>{totalPagination}</PaginationButton>
            )}
            <PaginationButton
                onClick={() => handleChange(currentLocation + 1)}
                disabled={currentLocation === totalPagination}
            >
                Next
            </PaginationButton>
        </PaginationWrapper>
    );
};
