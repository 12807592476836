import styled from 'styled-components';
import {offWhite, primaryAccent, spacingSix, white} from '../constants';
import React, {Children, FC, ReactElement, useState} from 'react';

interface StepperBarProps {
    currentPercentage: number;
}

export const StepperBar = styled.div<StepperBarProps>`
    height: 3px;
    border-radius: 1.5px;
    background-color: ${offWhite};
    position: relative;
    margin-bottom: ${spacingSix};
    &:after {
        content: '';
        display: block;
        height: 3px;
        border-radius: 1.5px;
        background-color: ${offWhite};
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${primaryAccent};
        width: ${(p) => p.currentPercentage}%;
        transition: width 120ms ease-out;
    }
`;

interface StepperProps {
    defaultStep: string;
    children: ReactElement<{name: string; title: string}>[];
}

export const Stepper: FC<StepperProps> = ({defaultStep, children}) => {
    const [activeStepName, setActiveStepName] = useState<string>(defaultStep);

    const setActiveChild = (currentStepName: string) => {
        setActiveStepName(currentStepName);
    };

    const activeStep = children!.filter((child) => child.props.name === activeStepName);
    const currentStep = children!.findIndex((child) => child.props.name === activeStepName);

    const getPercent = (index: number) => {
        return (index / (children.length - 1)) * 100;
    };

    return (
        <StepperHolder>
            <StepperBar currentPercentage={getPercent(currentStep)} />
            <div>
                {Children.toArray(children).map((child, i) => {
                    const c: {props: {name: string; title: string}} = child as {
                        props: {name: string; title: string};
                    };
                    return (
                        <Step
                            name={c.props.name}
                            title={c.props.title}
                            isActive={currentStep >= i}
                            setIsActive={() => setActiveChild(c.props.name)}
                            percentLeft={getPercent(i)}
                        />
                    );
                })}
            </div>
            {activeStep}
        </StepperHolder>
    );
};

const StepperHolder = styled.div`
    position: relative;
`;

interface StepProps {
    name: string;
    title: string;
    percentLeft: number;
    isActive: boolean;
    setIsActive: (name: string) => void;
}

const Step: FC<StepProps> = ({name, title, isActive, percentLeft, setIsActive}) => {
    return (
        <StepperDotHolder percentLeft={percentLeft}>
            <StepperDot key={name} isActive={isActive} onClick={() => setIsActive(name)} />
            <StepperText>{title}</StepperText>
        </StepperDotHolder>
    );
};

interface StepperDotHolderProps {
    percentLeft: number;
}

const StepperDotHolder = styled.span<StepperDotHolderProps>`
    position: absolute;
    left: ${(p) => p.percentLeft}%;
    top: -3px;
    z-index: 10;
`;

interface StepperDot {
    isActive: boolean;
}

const StepperDot = styled.span<StepperDot>`
    display: block;
    border: 1px solid ${white};
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: ${(p) => (p.isActive ? primaryAccent : offWhite)};
    z-index: 11;
`;

const StepperText = styled.span`
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
`;

interface StepperContentProps {
    name: string;
    title: string;
}

export const StepperContent = styled.div<StepperContentProps>`
    display: block;
`;
