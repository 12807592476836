import React from 'react';
import styled from 'styled-components';
import {white, grayPrimaryLightColor, black, disableGrey} from '../constants';

const DrawerContainer = styled.div<{visible?: boolean}>`
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
`;

const DrawerMask = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.45);
    animation: opacity 0.3s linear;
`;

const DrawerMainContent = styled.div<{width: number; visible?: boolean}>`
    position: fixed;
    z-index: 1200;
    right: 0;
    width: ${(props) => `${props.width}px`};
    height: 100%;
    background-clip: padding-box;
    background-color: ${white};
    border: 0;
    animation: moveRightIn 0.4s;
    @keyframes moveRightIn {
        0% {
            transform: ${(props) => `translateX(${props.width}px)`};
        }
        100% {
            transform: translate(0);
        }
    }
`;

const DrawerFooter = styled.div`
    border-top: 2px solid ${grayPrimaryLightColor};
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    text-align: right;
`;
const DrawerHeader = styled.div`
    position: relative;
    border-bottom: 2px solid ${grayPrimaryLightColor};
    width: 100%;
    padding: 16px 24px;
`;

const StyledCloseIcon = styled.span`
    position: absolute;
    right: 24px;
    top: 5px;
    font-size: 30px;
    color: ${disableGrey};
    z-index: 10;
    &:hover,
    &:focus {
        cursor: pointer;
        color: ${black};
    }
`;

const DrawerBody = styled.div`
    width: 100%;
    overflow: auto;
    padding: 24px;
`;

interface DrawerProps {
    title?: string;
    onClose?: () => void;
    visible?: boolean;
    width?: number;
    closableSign?: boolean;
    footer?: React.ReactNode;
}

export const Drawer: React.FC<DrawerProps> = (props) => {
    const {width = 256, footer, title, onClose, visible, children, closableSign = true} = props;
    return (
        <DrawerContainer visible={visible}>
            <DrawerMask
                onClick={() => {
                    if (onClose) onClose();
                }}
            />
            <DrawerMainContent visible={visible} width={width}>
                {closableSign && (
                    <StyledCloseIcon
                        onClick={() => {
                            if (onClose) onClose();
                        }}
                    >
                        &times;
                    </StyledCloseIcon>
                )}
                {title && <DrawerHeader>{title}</DrawerHeader>}
                <DrawerBody>{children}</DrawerBody>
                {footer && <DrawerFooter>{footer}</DrawerFooter>}
            </DrawerMainContent>
        </DrawerContainer>
    );
};
