import React, { FC, useContext, useEffect, useState } from 'react';
import getOrganisations from '../../api/get-organisations';
import getDiscountTier from '../../api/get-discount-tiers';
import getOrganisationDiscount from '../../api/get-organisation-discounts';
import { IdToken, IAuth0Claims, useAuth0WithCypress } from '@adaptavist-commerce/auth0-with-cypress-react';
import IOrganisation, { IOrganisationTier } from '../../interfaces/organisation/organisation.interface';
import IDiscountTier from '../../interfaces/discount-tiers/discount-tier.interface';
import { IDiscount } from '../../interfaces/discounts/discount.interface';
import IOrganisationContext, { IOrganisationProvider } from '../../interfaces/organisation-context.interface';
import getRbacByOrganisation from '../../api/get-rbac-by-organisation';

const OrganisationContext = React.createContext<IOrganisationContext>({
    organisation: null,
    userOrganisationDetails: null,
    currentOrganisationDetails: null,
    roles: [],
    permissions: [],
    isLoaded: false,
    hasRole: () => false,
    hasPermission: () => false,
    hasAnyRole: () => false,
    hasAnyPermission: () => false,
    getOrganisationTier: () => null,
    getOrganisationData: () => null,
    setOrganisation: () => { },
    setUserOrganisationDetails: () => { },
    setOrganisationsClaims: () => null,
});

const initialOrganisationState = {
    discountTier: null,
    organisationDiscounts: null,
    roles: [],
    permissions: [],
    organisationsData: null,
    organisation: null,
    userOrganisationDetails: null,
    currentOrganisationDetails: null,
    isLoaded: false,
};

const OrganisationProvider: FC<IOrganisationProvider> = ({ chellApiUrl, chellApiKey, initialOrganisation, children }) => {
    const [organisationState, setOrganisationState] = useState<{
        discountTier: IDiscountTier[] | null
        organisationDiscounts: IDiscount[] | null
        roles: string[]
        permissions: string[]
        organisationsData: IOrganisation[] | null,
        organisation: string | null,
        userOrganisationDetails: IOrganisation[] | null,
        currentOrganisationDetails: IOrganisation | null,
        isLoaded: boolean,
    }>({ ...initialOrganisationState, organisation: initialOrganisation });

    const { discountTier, organisationDiscounts, roles, permissions, organisationsData, organisation, userOrganisationDetails, currentOrganisationDetails, isLoaded } = organisationState;

    const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0WithCypress();

    useEffect(() => {
        (async () => {
            if (!isAuthenticated || !getAccessTokenSilently) return;
            try {
                const accessToken = await getAccessTokenSilently();
                const claims: any = await getIdTokenClaims();
                if (!claims.hasOwnProperty('https://my-adaptavist.com/authorization')) return;
                const nextOrganisationsClaim = claims['https://my-adaptavist.com/authorization']?.organisations || null;
                let nextOrganisation = Object.keys(nextOrganisationsClaim).length ? Object.keys(nextOrganisationsClaim)[0] : null;
                const storedOrganisation = localStorage.getItem('storedOrganisationKey');
                if(storedOrganisation){
                    nextOrganisation = JSON.parse(storedOrganisation).organisation;
                }
                let organisationsData = null, rbac = null, discountTier = null, organisationDiscounts = null;
                if (nextOrganisation) {
                    [organisationsData, rbac, discountTier, organisationDiscounts] = await Promise.all([
                        getOrganisations(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
                        getRbacByOrganisation(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
                        getDiscountTier(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
                        getOrganisationDiscount(chellApiUrl, chellApiKey, accessToken, nextOrganisation)
                    ]);
                }
                const userOrganisationDetails = organisationsData?.filter((o: IOrganisation) =>
                    Object.keys(nextOrganisationsClaim).includes(o.organisation)
                ) || null;
                const currentOrganisationDetails = organisationsData?.find((o: IOrganisation) => o.organisation === nextOrganisation) || null;
                setOrganisationState({
                    discountTier,
                    organisationDiscounts,
                    roles: rbac?.roles || [],
                    permissions: rbac?.permissions || [],
                    organisationsData,
                    organisation: nextOrganisation,
                    userOrganisationDetails,
                    currentOrganisationDetails,
                    isLoaded: true
                });
            } catch (error) {
                setOrganisationState(initialOrganisationState);
            }
        })();
    }, [isAuthenticated]);

    const setOrganisation = async (nextOrganisation: string) => {
        setOrganisationState(prevState => ({
            ...prevState,
            isLoaded: false
        }));
        const accessToken = await getAccessTokenSilently();
        const [rbac, discountTier, organisationDiscounts] = await Promise.all([
            getRbacByOrganisation(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
            getDiscountTier(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
            getOrganisationDiscount(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
        ]);
        const currentOrganisationDetails = organisationsData?.find((o: IOrganisation) => o.organisation === nextOrganisation) || null;
        localStorage.setItem(
            'storedOrganisationKey',
            JSON.stringify({
                companyName: currentOrganisationDetails?.companyName,
                organisation: nextOrganisation,
            })
        );
        setOrganisationState(prevState => ({
            ...prevState,
            organisation: nextOrganisation,
            roles: rbac?.roles || [],
            permissions: rbac?.permissions || [],
            discountTier,
            organisationDiscounts,
            currentOrganisationDetails,
            isLoaded: true
        }));
    };

    const setUserOrganisationDetails = (userOrganisationDetails: IOrganisation[] | null) => {
        setOrganisationState(prevState => ({
            ...prevState,
            userOrganisationDetails: userOrganisationDetails
        }));
    };

    const setOrganisationsClaims = async (claims: IdToken | IAuth0Claims) => {
        setOrganisationState(initialOrganisationState);
        const newOrganisationsClaim = claims['https://my-adaptavist.com/authorization'].organisations;
        if (!newOrganisationsClaim) return;
        const newOrganisation = Object.keys(newOrganisationsClaim)[0];
        const [organisationsData, rbac, discountTier, organisationDiscounts] = await fetchOrganisationData(newOrganisation);
        const userOrganisationDetails = organisationsData?.filter((o: IOrganisation) =>
            Object.keys(newOrganisationsClaim).includes(o.organisation)
        ) || null;
        const currentOrganisationDetails = organisationsData?.find((o: IOrganisation) => o.organisation === newOrganisation) || null;
        setOrganisationState({
            discountTier,
            organisationDiscounts,
            roles: rbac?.roles || [],
            permissions: rbac?.permissions || [],
            organisationsData,
            organisation: newOrganisation,
            userOrganisationDetails,
            currentOrganisationDetails,
            isLoaded: true
        });
    };

    const fetchOrganisationData = async (nextOrganisation: string) => {
        const accessToken = await getAccessTokenSilently();
        return await Promise.all([
            getOrganisations(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
            getRbacByOrganisation(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
            getDiscountTier(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
            getOrganisationDiscount(chellApiUrl, chellApiKey, accessToken, nextOrganisation),
        ]);
    };

    const getTierName = (): string | null => {
        if (!discountTier || !organisationDiscounts) return null;
        return (
            discountTier.find((tier) => {
                let foundDiscounts = false;
                for (const discount of organisationDiscounts) {
                    if (tier.discounts?.includes(discount.uuid)) {
                        foundDiscounts = true;
                    }
                }
                return foundDiscounts ? tier : null;
            })?.name || null
        );
    };

    const getOrganisationTier = (): IOrganisationTier | null => {
        if (!organisationsData || !organisation || !organisationDiscounts) return null;
        return {
            name: getTierName() || '',
            discounts: organisationDiscounts,
        };
    };

    const getOrganisationData = (): IOrganisation | null => {
        if (!organisationsData || !organisation) return null;
        const data = organisationsData.find((o) => o.organisation === organisation) || null;
        if (!data || !organisationDiscounts) return null;
        data.tier = {
            name: getTierName() || '',
            discounts: organisationDiscounts,
        };
        return data;
    };

    const hasRole = (role: string) => roles.includes(role);
    const hasAnyRole = (allowedRoles: string[]) => roles.some((role) => allowedRoles.includes(role));
    const hasPermission = (permission: string) => permissions.includes(permission);
    const hasAnyPermission = (allowedPermissions: string[]) => permissions.some((permission) => allowedPermissions.includes(permission));

    return (
        <OrganisationContext.Provider
            value={{
                organisation,
                userOrganisationDetails,
                currentOrganisationDetails,
                roles,
                permissions,
                isLoaded,
                hasRole,
                hasPermission,
                hasAnyRole,
                hasAnyPermission,
                getOrganisationTier,
                getOrganisationData,
                setOrganisation,
                setUserOrganisationDetails,
                setOrganisationsClaims,
            }}
        >
            {children}
        </OrganisationContext.Provider>
    );
};

const useOrganisation = (): IOrganisationContext => useContext(OrganisationContext);

export default useOrganisation;

export { OrganisationProvider, OrganisationContext };
