import React, { FC } from 'react';
import { Formik, Form, FormikHelpers } from "formik";
import useTheme from '../context/theme-provider/theme-provider';
import useAddressFormContext from '../context/address-form-provider/address-form-provider';
import { IAddressFields } from '../../interfaces/IAddressFields';

export interface IAddressFormProps { handleSubmitCallBack: (values: any, actions: FormikHelpers<IAddressFields>) => void }

const AddressFormFormik: FC<IAddressFormProps> = ({ handleSubmitCallBack, children }) => {

    const { theme } = useTheme();

    const {
        allowedFields,
        validationSchema,
        initialValues,
        apiUrl,
        apiKey,
        countryISOCode
    } = useAddressFormContext();

    return (
        <div className={theme.addressFormFormikBox}>
            {
                !apiUrl || !apiKey || !countryISOCode ?
                    <div className={theme.formErrorBox}>
                        <p className={theme.formErrorBoxText}>Loading Error</p>
                    </div> :
                    <div className={theme.addressForm}>
                        {
                            allowedFields?.length && Object.keys(initialValues).length ?
                                <Formik
                                    onSubmit={handleSubmitCallBack}
                                    validationSchema={validationSchema}
                                    enableReinitialize={true}
                                    initialValues={{
                                        ...initialValues
                                    }}
                                >
                                    <Form >
                                        <div className={theme.addressFormBox}>
                                            {children}
                                        </div>
                                    </Form>
                                </Formik> :
                                <div className={theme.loadingBox}>
                                    <p className={theme.loadingBoxText}>Loading</p>
                                </div>
                        }
                    </div>
            }
        </div>
    );

};


export default AddressFormFormik;