import { IAtlassianDiscount, IDiscount, IResponseDiscount } from '../../interfaces/discounts/discount.interface';

export const discountFactory = (discounts: IResponseDiscount[]): IDiscount[] | IAtlassianDiscount[] => {
    return discounts.map((discount) => {
        const { ruleData, discountType, ...otherProps } = discount;
        if (ruleData) {
            return {
                ...otherProps,
                type: discountType,
                ...ruleData,
            };
        }
        return {
            ...otherProps,
            type: discountType,
        };
    });
};
