import React from 'react';
import {DangerButton} from './danger-button';
import {PrimaryButton} from './primary-button';
import {SecondaryButton} from './secondary-button';
import {TextButton} from './text-button';
import {ButtonProps} from './styled-button';

export const Button: React.FC<ButtonProps> = (props) => {
    const {buttonType = 'primary', children, loading = false, ...otherProps} = props;

    switch (buttonType) {
        case 'text':
            return (
                <TextButton {...otherProps} loading={loading}>
                    {children}
                </TextButton>
            );
        case 'danger':
            return (
                <DangerButton {...otherProps} loading={loading}>
                    {children}
                </DangerButton>
            );
        case 'secondary':
            return (
                <SecondaryButton {...otherProps} loading={loading}>
                    {children}
                </SecondaryButton>
            );
        case 'primary':
        default:
            return (
                <PrimaryButton {...otherProps} loading={loading}>
                    {children}
                </PrimaryButton>
            );
    }
};
