import axios from 'axios';

const getAllowedFields = async (apiKey: string, apiURL: string, countryCode: string, validationSchema: string | null) => {
    
    const headers = { "x-api-key": apiKey };
        
    const res = await axios.get(`${apiURL}/allowed-fields?countryCode=${countryCode}${validationSchema ? `&validationSchema=${validationSchema}` : ''}`, { headers });

    return res.data;
    
};

export default getAllowedFields;
