import styled from 'styled-components';
import {graySecondaryLightColor} from '../constants';

export const Header = styled.header<{height?: string}>`
    position: relative;
    height: ${(props) => (props.height ? props.height : '80px')};
    width: 100%;
    box-shadow: 0 2px 1px ${graySecondaryLightColor};
    padding: 16px 0;
`;
