import React from 'react';
import styled from 'styled-components';
import {primaryAccent, white} from '../constants';

const RadioButtonContainer = styled.label`
    display: inline-block;
    position: relative;
    padding-left: 25px;
    padding-right: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: all 400ms;
    &:hover input ~ span {
        border: 1px solid ${primaryAccent};
    }
    & input:checked ~ span {
        background-color: ${white};
        border: 1px solid ${primaryAccent};
        &:after {
            display: block;
        }
    }
    & span:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${primaryAccent};
    }
`;

const RadioButtonInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

const RadioCheckmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    &:after {
        content: '';
        position: absolute;
        display: none;
    }
`;

interface RadioButtonI extends React.HtmlHTMLAttributes<HTMLInputElement> {
    style?: React.CSSProperties;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButton: React.FC<RadioButtonI> = (props) => {
    const {children, style, ...moreProps} = props;
    return (
        <div style={style || {display: 'inline-block'}}>
            <RadioButtonContainer>
                {children}
                <RadioButtonInput type="radio" {...moreProps} />
                <RadioCheckmark />
            </RadioButtonContainer>
        </div>
    );
};
