import axios from 'axios';
import IDiscountTier from '../interfaces/discount-tiers/discount-tier.interface';

const getDiscountTier = async (chellApiUrl: string, chellApiKey: string, accessToken: string, organisation: string): Promise<IDiscountTier[]> => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Organisation: organisation,
        'x-api-key': chellApiKey,
    };

    const response = await axios.get<IDiscountTier[]>(
        `${chellApiUrl}/discount-tiers`,
        { headers }
    );

    return response.data;
};

export default getDiscountTier;
