import React, {Fragment} from 'react';
import styled from 'styled-components';
import {black, successColorHalf, successColorLight, errorColorHalf, errorColorLight} from '../constants';
import {infoColorHalf, infoColorLight, warningColorHalf, warningColorLight} from '../constants';
import {ReactComponent as CloseIcon} from '../icons/close.svg';

const StyledAlert = styled.div<{visible: boolean}>`
    position: relative;
    color: ${black};
    padding: 8px 15px;
    border-radius: 2px;
    width: 100%;
    font-size: 14px;
    animation: ${(props) => (props.visible ? 'openContent' : 'closeContent')} 0.5s normal forwards ease-in-out;
    @keyframes openContent {
        from {
            transform: scaleY(0);
        }
        to {
            transform: scaleY(1);
            display: block;
        }
    }
    @keyframes closeContent {
        from {
            transform: scaleY(1);
        }
        to {
            transform: scaleY(0);
            display: none;
            height: 0;
        }
    }
`;

const StyledAlertSuccess = styled(StyledAlert)`
    background-color: ${successColorLight};
    border: 1px solid ${successColorHalf};
`;
const StyledAlertError = styled(StyledAlert)`
    border: 1px solid ${errorColorHalf};
    background-color: ${errorColorLight};
`;
const StyledAlertInfo = styled(StyledAlert)`
    border: 1px solid ${infoColorHalf};
    background-color: ${infoColorLight};
`;
const StyledAlertWarning = styled(StyledAlert)`
    border: 1px solid ${warningColorHalf};
    background-color: ${warningColorLight};
`;

const StyledCloseButton = styled.span`
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    vertical-align: -0.125px;
    transition: color 400ms;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    &:hover,
    &:focus {
        color: ${black};
    }
`;

interface AlertProps {
    message?: string;
    type?: 'success' | 'error' | 'info' | 'warning';
    closable?: boolean;
}

export const Alert: React.FC<AlertProps> = (props) => {
    const {message, children, closable, type = 'info'} = props;
    const [alertVisible, setAlertVisible] = React.useState(true);

    const AlertContent = () => (
        <Fragment>
            {closable && (
                <StyledCloseButton onClick={() => setAlertVisible(false)}>
                    <CloseIcon />
                </StyledCloseButton>
            )}
            {message || children}
        </Fragment>
    );
    if (!alertVisible) return null;
    switch (type) {
        case 'success':
            return (
                <StyledAlertSuccess visible={alertVisible}>
                    <AlertContent />
                </StyledAlertSuccess>
            );
        case 'error':
            return (
                <StyledAlertError visible={alertVisible}>
                    <AlertContent />
                </StyledAlertError>
            );
        case 'warning':
            return (
                <StyledAlertWarning visible={alertVisible}>
                    <AlertContent />
                </StyledAlertWarning>
            );
        default:
            return (
                <StyledAlertInfo visible={alertVisible}>
                    <AlertContent />
                </StyledAlertInfo>
            );
    }
};
