import React, {FC} from 'react';
import useTheme from '../context/theme-provider/theme-provider';
import useAddressFormContext from '../context/address-form-provider/address-form-provider';
import CountrySelectField from '../form-fields/country-select-field/country-select-field';
import RegionSelectField from '../form-fields/region-select-field/region-select-field';
import InputField from '../form-fields/input-field/input-field';

const AddressFormFields: FC = () => {

    const { theme } = useTheme();

    const {
        allowedFields,
        countryChoices,
        countryISOCode,
        countryRegionChoices,
        customLabelOverrides,
        labelOverrides,
        handleCountryChange
    } = useAddressFormContext();

    return (
        <div className={theme.addressFormFieldsBox}>
            {
                allowedFields!.map((field: string) => {
                    if (field === "isoCountryCode") {
                        return <CountrySelectField key={field} countries={countryChoices!} countryChangeHandler={handleCountryChange} alternateLabel={customLabelOverrides?.[countryISOCode]?.[field] || labelOverrides?.[field]}/>
                    } else if (field === "region") {
                        return <RegionSelectField key={field} regions={countryRegionChoices!} alternateLabel={customLabelOverrides?.[countryISOCode]?.[field] || labelOverrides?.[field]}/>
                    } else {
                        return <InputField key={field} fieldName={field} alternateLabel={customLabelOverrides?.[countryISOCode]?.[field] || labelOverrides?.[field]}/>
                    }
                })
            }
        </div>
    );
};

export default AddressFormFields;