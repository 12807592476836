import React, { FC } from 'react';
import { Field, ErrorMessage } from "formik";
import useTheme from '../../context/theme-provider/theme-provider';
import { camelCaseToSentenceCase } from '../../../utilities/string-functions';
import { IInputProps } from '../../../interfaces/field-interfaces/IInputProps';

const InputField: FC<IInputProps> = ({ fieldName, alternateLabel }: IInputProps) => {

    const { theme } = useTheme();

    return (
        <div className={`${theme.inputBox} ${theme[fieldName + "InputBox"]}`}>
            <label htmlFor={fieldName} className={`${theme.label} ${theme[fieldName + "Label"]}`}>{camelCaseToSentenceCase(alternateLabel || fieldName)} </label>
            <Field id={fieldName} className={`${theme.inputFormField} ${theme[fieldName + "InputField"]}`} name={fieldName} />
            <div className={`${theme.errorMessageBox} ${theme[fieldName + "ErrorMessageBox"]}`}>
                <ErrorMessage name={fieldName} className={`${theme.errorMessage} ${theme[fieldName + "ErrorMessage"]}`} />
            </div>
        </div>
    );

};

export default InputField;