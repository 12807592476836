import React, {Fragment} from 'react';
import styled from 'styled-components';
import {
    errorRed,
    fontSizeSmall,
    offWhite,
    spacingOne,
    spacingTwo,
    black,
    fontSizeOne,
    primaryAccent,
    lightGrey,
    disableGrey,
} from '../constants';

export const FormField = styled.div`
    margin-bottom: 1rem;
    & label {
        display: block;
        margin-bottom: ${spacingOne};
    }
`;

export const FormFieldInline = styled.div`
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & * {
        margin-right: ${spacingTwo};
    }
`;

export const Label = styled.label`
    font-size: ${fontSizeSmall};
`;

export const ErrorMessageBox = styled.div`
    /* margin-top: ${spacingOne}; */
    display: block;
    color: ${errorRed};
    font-size: ${fontSizeSmall};
    top: 3px;
    margin-bottom: 5.5px;
    min-height: 21px;
    line-height: 1.5;
    & p {
        animation: moveTopDown 0.5s ease-in-out;
    }
    @keyframes moveTopDown {
        0% {
            opacity: 0;
            transform: translateY(-5px);
        }
        80% {
            opacity: 0.5;
            transform: translateY(-2.5px);
        }
        100% {
            opacity: 1;
            transform: translate(0);
        }
    }
`;

// INPUT
export const StyledInput = styled.input<any>`
    display: inline-block;
    border: none;
    border-radius: ${(props) => {
        if (props.addonBefore) {
            return '0px 4px 4px 0';
        } else if (props.addonAfter) {
            return '4px 0 0 4px';
        } else return '4px';
    }};
    &:focus,
    &:active {
        outline: ${primaryAccent} auto 2px;
        border-radius: 0;
    }
    background-color: ${offWhite};
    color: ${black};
    font-size: ${fontSizeOne};
    padding: 7px 14px;
    height: 39px;
    width: 100%;
    box-sizing: border-box;
    &[type='checkbox'] {
        min-height: auto;
    }
    &[type='search'] {
        background-image: url('../icons/clear.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    &[type='search']::-webkit-search-cancel-button {
        position: relative;
        right: 0;
        background: ${lightGrey};
    }
    &:disabled {
        background-color: ${lightGrey};
        color: ${offWhite};
        color: ${disableGrey};
        box-shadow: none;
        border: none;
        cursor: not-allowed;
        border: 2px solid ${offWhite};
    }
`;
export const InputWrapper = styled.span`
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    transition: all 0.3s;
    display: inline-flex;
`;
export const InputWrapperGroup = styled.span`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
`;
export const InputAddOnBefore = styled.span`
    height: 39px;
    padding: 10px 15px;
    color: ${black};
    font-weight: 400;
    font-size: ${fontSizeOne};
    text-align: left;
    background-color: ${lightGrey};
    border: 1px solid ${lightGrey};
    border-radius: 4px 0 0 4px;
`;

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    addonBefore?: string | React.ReactNode;
    errorMessage?: string;
}

export const Input = (props: InputProps) => {
    const {addonBefore, errorMessage, ...moreProps} = props;
    return (
        <Fragment>
            <InputWrapperGroup>
                {addonBefore && <InputAddOnBefore>{addonBefore}</InputAddOnBefore>}
                <InputWrapper>
                    <StyledInput addonBefore={addonBefore} {...moreProps} />
                </InputWrapper>
            </InputWrapperGroup>
            <ErrorMessageBox>
                <p>{errorMessage}</p>
            </ErrorMessageBox>
        </Fragment>
    );
};

export const TextArea = styled(Input);
