import React, {Children, FC, ReactElement, useState} from 'react';
import styled from 'styled-components';
import {black, offWhite, primaryAccent, spacingThree, white} from '../constants';

const TabHead = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const TabButton = styled.button<{isActive: boolean}>`
    position: relative;
    bottom: -1px;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid ${offWhite};
    background-color: ${white};
    padding: 8px 16px;
    margin-right: 4px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: ${(p) => (p.isActive ? primaryAccent : black)};
    &:hover,
    &:focus,
    &:active {
        color: ${primaryAccent};
        outline: none;
    }
    border-bottom: ${(p) => (p.isActive ? `1px solid ${white}` : `1px solid ${offWhite}`)};
`;

const TabBody = styled.div`
    border-top: 1px solid ${offWhite};
    padding-top: ${spacingThree};
`;

const TabbedContent = styled.div`
    width: 100%;
`;

interface TabbedContainerProps {
    defaultTab: string;
    children: ReactElement<{name: string; title: string}>[];
}

export const TabbedContainer: FC<TabbedContainerProps> = ({defaultTab, children}) => {
    const [activeTabName, setActiveTabName] = useState<string>(defaultTab);

    const setActiveChild = (currentTabName: string) => {
        setActiveTabName(currentTabName);
    };

    const activeTab = children!.filter((child) => child.props.name === activeTabName);

    return (
        <TabbedContent>
            <TabHead>
                {Children.map(children, (child) => (
                    <TabButton
                        isActive={child.props.name === activeTabName}
                        onClick={() => setActiveChild(child.props.name)}
                    >
                        {child.props.title}
                    </TabButton>
                ))}
            </TabHead>
            {activeTab}
        </TabbedContent>
    );
};

export const Tab: FC<{name: string; title: string}> = ({children}) => <TabBody>{children}</TabBody>;
