import React from 'react';
import styled from 'styled-components';
import {primaryAccent, white} from '../constants';
import {ButtonContent, ButtonProps, StyledButton} from './styled-button';

// PRIMARY
export const StyledPrimaryButton = styled(StyledButton)`
    border: 1px solid ${primaryAccent};
    background-color: ${primaryAccent};
`;
export const PrimaryButtonGhost = styled(StyledButton)`
    border: 1px solid ${primaryAccent};
    background-color: ${white};
    color: ${primaryAccent};
    &:hover,
    &:focus {
        border: 1px solid ${primaryAccent};
        background-color: ${primaryAccent};
        color: ${white};
    }
`;

export const PrimaryButton: React.FC<ButtonProps> = (props) => {
    const {children, loading = false, disabled = false, ...otherProps} = props;

    if (props.ghost)
        return (
            <PrimaryButtonGhost {...otherProps} disabled={disabled}>
                <ButtonContent loading={loading} color={primaryAccent}>
                    {children}
                </ButtonContent>
            </PrimaryButtonGhost>
        );
    return (
        <StyledPrimaryButton {...otherProps} disabled={disabled}>
            <ButtonContent loading={loading}>{children}</ButtonContent>
        </StyledPrimaryButton>
    );
};
