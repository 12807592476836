import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {
    black,
    fontSizeOne,
    graySecondaryLightColor,
    offWhite,
    primaryAccent,
    white,
    lighterGrey,
    lightGrey,
    disableGrey,
} from '../constants';

const SelectWrapper = styled.div`
    position: relative;
    user-select: none;
    width: 100%;
`;

const StyledSelect = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
`;

const OptionsWrapper = styled.div<{open: boolean; dropUp: boolean}>`
    position: absolute;
    display: ${(props) => (props.open ? 'block' : 'none')};
    top: ${(props) => (props.dropUp ? 'none' : '100%')};
    bottom: ${(props) => (props.dropUp ? '100%' : 'none')};
    left: 0;
    right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 7px 0px;
    border-top: 0;
    background: ${white};
    transition: all 0.5s;
    opacity: ${(props) => (props.open ? 1 : 0)};
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    pointer-events: ${(props) => (props.open ? 'all' : 'none')};
    z-index: 200;
    box-shadow: 0 1.5px 6px ${graySecondaryLightColor};
`;

const dropdownHeight = 400;

const Options = styled.div`
    overflow-y: scroll;
    max-height: ${`${dropdownHeight}px`};
    background: transparent;
`;

const SelectTrigger = styled.div<{open: boolean; disabled?: boolean}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    line-height: 39px;
    background: ${white};
    cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
    border-radius: 4px;

    background-color: ${(props) => (props.disabled ? `${lightGrey}` : `${offWhite}`)};
    color: ${(props) => (props.disabled || props.open ? `${disableGrey}` : `${black}`)};
    font-size: ${fontSizeOne};
    padding: 7px 14px;
    height: 39px;
    width: 100%;
    box-sizing: border-box;
    outline: ${(props) => (props.open ? `${primaryAccent}` : props.disabled ? `${offWhite}` : `${white}`)} auto 2px;
    transition: all 0.5s;
    &:focus,
    &:active {
        outline: ${(props) => (props.disabled ? `none` : `${primaryAccent}`)} auto 2px;
        border-radius: 0;
    }
`;

const Arrow = styled.div<{disabled?: boolean}>`
    position: relative;
    height: 10px;
    width: 15px;
    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 1.49px;
        height: 100%;
        transition: all 0.5s;
        background-color: ${(props) => (props.disabled ? `${disableGrey}` : `${black}`)};
    }
    &::before {
        left: -3px;
        transform: rotate(-35deg);
    }
    &::after {
        left: 3px;
        transform: rotate(35deg);
    }
`;

const SearchFieldWrapper = styled.div<{dropUp: boolean}>`
    bottom: 0;
    padding: 7px;
    background-color: ${lighterGrey};
`;

const SearchField = styled.input`
    width: 100%;
    outline: none;
    height: 39px;
    border-radius: 4px;
    border-style: solid;
    padding: 7px;
    border-color: ${lighterGrey};
`;

const Placeholder = styled.span`
    color: ${disableGrey};
`;
const SelectText = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface returnPayload {
    name: string;
    value: string;
}
export interface SelectI {
    name?: string;
    defaultValue?: string;
    disabled?: boolean;
    placeholder?: string;
    onSearch?: (value: string) => void;
    searchPlaceholder?: string;
    onChange?: (target: returnPayload) => void;
}

export const Select: React.FC<SelectI> = (props) => {
    const node = useRef<any>();
    const {onChange, onSearch, children, defaultValue, disabled} = props;
    const {searchPlaceholder = 'Search...', name = 'select', placeholder} = props;
    const [open, setOpen] = useState(false);
    const [dropUp, setDropUp] = useState(false);

    const [selectState, setSelectState] = useState({
        value: defaultValue || '',
        label: '',
    });

    const handleClick = (event: any) => {
        if (node.current.contains(event.target)) {
            if (window.innerHeight - event.y < dropdownHeight && event.target.id === 'select-text') {
                setDropUp(true);
            } else {
                setDropUp(false);
            }
            // inside click
            setOpen(true);
            return;
        }
        // outside click
        setOpen(false);
    };

    useEffect(() => {
        React.Children.map(children, (child: any) => {
            if (child.props.value === defaultValue) {
                setSelectState({
                    value: child.props.value,
                    label: child.props.children,
                });
            }
        });

        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        if (onSearch) {
            onSearch(value);
        }
    };

    const {value, label} = selectState;
    const handleChange = (child: any) => {
        return React.cloneElement(child, {
            selected: child.props.value === value ? true : false,
            onClick: () => {
                const optionValue = child.props.value;
                if (optionValue) {
                    if (onChange) {
                        onChange({name, value: optionValue});
                    }
                    setSelectState({
                        value: optionValue,
                        label: child.props.children,
                    });
                }
                setOpen(false);
            },
        });
    };

    const newChildren = React.Children.map(children, handleChange);

    return (
        <SelectWrapper ref={node}>
            <StyledSelect>
                <SelectTrigger open={open} defaultValue={value} disabled={disabled}>
                    <SelectText id="select-text">{label || <Placeholder>{placeholder}</Placeholder>}</SelectText>
                    <Arrow disabled={disabled} />
                </SelectTrigger>
                <OptionsWrapper open={open} dropUp={dropUp}>
                    {onSearch && !dropUp && (
                        <SearchFieldWrapper dropUp={dropUp}>
                            <SearchField placeholder={searchPlaceholder} onChange={onSearchField} />
                        </SearchFieldWrapper>
                    )}
                    <Options>{newChildren}</Options>
                    {onSearch && dropUp && (
                        <SearchFieldWrapper dropUp={dropUp}>
                            <SearchField placeholder={searchPlaceholder} onChange={onSearchField} />
                        </SearchFieldWrapper>
                    )}
                </OptionsWrapper>
            </StyledSelect>
        </SelectWrapper>
    );
};

const StyledOption = styled.option<{defaultChecked?: boolean}>`
    position: relative;
    display: block;
    padding: 7px 14px;
    font-size: ${fontSizeOne};
    color: ${black};
    background-color: ${(props) => (props.defaultChecked ? `${offWhite}` : `${white}`)};
    line-height: 39px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    word-wrap: break-word;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        cursor: pointer;
        background-color: ${offWhite};
    }
    &:disabled {
        background-color: ${lightGrey};
        color: ${disableGrey};
        box-shadow: none;
        cursor: not-allowed;
    }
`;

export interface OptionI {
    value: string;
    label?: string;
    disabled?: boolean;
    selected?: boolean;
}

export const Option: React.FC<OptionI> = (props) => {
    const {selected, ...moreProps} = props;
    return (
        <StyledOption defaultChecked={selected} {...moreProps}>
            {props.children}
        </StyledOption>
    );
};
