import React, { FC } from 'react';
import { Field, useFormikContext, ErrorMessage } from "formik";
import { camelCaseToSentenceCase } from '../../../utilities/string-functions';
import useTheme from '../../context/theme-provider/theme-provider';
import { ICountryChoices } from '../../../interfaces/ICountryChoices';
import { ICountrySelectProps } from '../../../interfaces/field-interfaces/ICountrySelectProps';

const CountrySelectField: FC<ICountrySelectProps> = ({ alternateLabel, countries, countryChangeHandler }: ICountrySelectProps) => {

    const { theme } = useTheme();
    const { setFieldValue, resetForm, values } = useFormikContext();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        countryChangeHandler(event.target.value, values);
        setFieldValue('country', event.target.value);
        resetForm();
    };

    return (
        <div className={`${theme.inputBox} ${theme.countryInputBox}`}>
            <label htmlFor={'country'} className={`${theme.label} ${theme.countryLabel}`}>{camelCaseToSentenceCase(alternateLabel || "Country")}</label>
            <Field id={'country'} className={`${theme.inputFormField} ${theme.countryDropDownSelect}`} name='isoCountryCode' as="select" onChange={handleChange}>
                {countries.map((option: ICountryChoices) => {
                    return <option key={option.name} className={theme.countryOptions} value={option.alphaTwoCode}>{option.name}</option>
                })}
            </Field>
            <div className={`${theme.errorMessageBox} ${theme.countryErrorMessageBox}`} >
                <ErrorMessage name={'isoCountryCode'} className={`${theme.errorMessage} ${theme.countryErrorMessage}`} />
            </div>
        </div>
    );

};

export default CountrySelectField;