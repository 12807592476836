import React, { useEffect, useState } from 'react'
import { IAuth0CypressToken } from './interfaces'
import { Auth0ProviderOptions, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import Auth0WithCypressContext, { IAuth0WithCypress } from './auth0-cypress-context'

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <Auth0WithCypressProvider>.')
}

export interface Auth0WithCypressProviderOptions extends Auth0ProviderOptions {
    cypressTokenStoreName?: string
}

/**
 * ```jsx
 * <Auth0WithCypressProvider
 *   domain={domain}
 *   clientId={clientId}
 *   redirectUri={window.location.origin}>
 *   <MyApp />
 * </Auth0WithCypressProvider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 */
export const Auth0WithCypressProvider = (options: Auth0WithCypressProviderOptions): JSX.Element => {
    const { children, cypressTokenStoreName = 'auth0Cypress' } = options
    const auth0Details = useAuth0()
    const [cypressAuthDetails, setAuthDetails] = useState<IAuth0WithCypress>({
        ...auth0Details,
        getIdTokenClaims: stub,
    })

    useEffect(() => {
        // @ts-ignore
        if (window.Cypress) {
            const auth0Token: IAuth0CypressToken = JSON.parse(
                localStorage.getItem(cypressTokenStoreName)!,
            )
            const getAccessTokenCypress = async () => {
                if (!auth0Token?.body) throw new Error('Missing access token')
                return auth0Token.body.access_token
            }
            const getIdTokenClaimsCypress = async () => {
                if (!auth0Token?.body?.decodedToken) throw new Error('Missing token claims')
                return auth0Token.body.decodedToken.claims
            }
            setAuthDetails((prevState) => ({
                ...prevState,
                isAuthenticated: true,
                isLoading: false,
                getIdTokenClaims: getIdTokenClaimsCypress,
                getAccessTokenSilently: getAccessTokenCypress,
                user: (auth0Token && auth0Token.body.decodedToken.user) || null,
            }))
            return
        }
        setAuthDetails({
            ...auth0Details,
        })
    }, [options])

    return (
        <Auth0WithCypressContext.Provider value={{ ...cypressAuthDetails }}>
            {children}
        </Auth0WithCypressContext.Provider>
    )
}

const Auth0Wrapper = (options: Auth0WithCypressProviderOptions): JSX.Element => {
    const { children } = options
    return (
        <Auth0Provider {...options}>
            <Auth0WithCypressProvider {...options}>{children}</Auth0WithCypressProvider>
        </Auth0Provider>
    )
}

export default Auth0Wrapper
