import styled from 'styled-components';
import {lighterGrey, lightestGrey, offWhite, spacingThree, white} from '../constants';

interface TableProps {
    striped?: boolean;
}

export const Table = styled.table<TableProps>`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: ${spacingThree};
    & td,
    & th {
        padding: 16px 8px 16px 8px;
        vertical-align: baseline;
    }

    & th {
        font-size: 1rem;
        border-top: 1px solid ${offWhite};
        border-bottom: 2px solid ${offWhite};
        box-shadow: none;
        background-color: ${white};
    }

    & tr {
        transition: 240ms background-color ease-in-out;
    }

    & tr:nth-child(even) {
        background-color: ${(p) => (p.striped ? lightestGrey : white)};
    }

    & tr:hover {
        background-color: ${lighterGrey};
    }
`;

export const ListTable = styled.table<TableProps>`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: ${spacingThree};

    & td,
    & th {
        padding: 0 0 8px;
        vertical-align: baseline;
    }

    & th {
        font-size: 1rem;
    }
`;

interface TableCellProps {
    align?: 'left' | 'right' | 'center';
}

export const TableCell = styled.td<TableCellProps>`
  text-align: ${(p) => p.align || 'left'}};
`;

export const TableHeader = styled.th<TableCellProps>`
  text-align: ${(p) => p.align || 'left'}};
`;
