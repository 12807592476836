import React from 'react';
import styled from 'styled-components';
import {StyledButton, ButtonProps, ButtonContent} from './styled-button';
import {white, errorRed} from '../constants';

export const StyledDangerButton = styled(StyledButton)`
    border: 1px solid ${errorRed};
    background-color: ${errorRed};
`;
export const StyledDangerButtonGhost = styled(StyledButton)`
    border: 1px solid ${errorRed};
    background-color: ${white};
    color: ${errorRed};
    &:hover,
    &:focus {
        border: 1px solid ${errorRed};
        background-color: ${errorRed};
        color: ${white};
    }
`;

export const DangerButton: React.FC<ButtonProps> = (props) => {
    const {children, loading = false, disabled = false, ...otherProps} = props;

    if (props.ghost)
        return (
            <StyledDangerButtonGhost {...otherProps} disabled={disabled}>
                <ButtonContent loading={loading} color={errorRed}>
                    {children}
                </ButtonContent>
            </StyledDangerButtonGhost>
        );
    return (
        <StyledDangerButton {...otherProps} disabled={disabled}>
            <ButtonContent loading={loading}>{children}</ButtonContent>
        </StyledDangerButton>
    );
};
