import React from 'react';
import styled from 'styled-components';
import {primaryAccent, white, errorRed} from '../constants';
import {ButtonContent, ButtonProps, StyledButton} from './styled-button';

const StyledTextButton = styled(StyledButton)`
    color: ${primaryAccent};
    border: 1px solid ${white};
    background-color: ${white};
    &:hover,
    &:focus {
        box-shadow: none;
    }
`;
const TextButtonBorder = styled(StyledButton)`
    background-color: ${white};
    border: 1px solid ${white};
    &:hover,
    &:focus {
        border: 1px solid ${errorRed};
        background-color: ${white};
        color: ${errorRed};
    }
`;

export const TextButton: React.FC<ButtonProps> = (props) => {
    const {children, loading = false, disabled = false, ...otherProps} = props;
    if (props.borderOnHover && props.danger)
        return (
            <TextButtonBorder {...otherProps} disabled={disabled}>
                <ButtonContent loading={loading} color={errorRed}>
                    {children}
                </ButtonContent>
            </TextButtonBorder>
        );
    return (
        <StyledTextButton {...otherProps} disabled={disabled}>
            <ButtonContent loading={loading} color={primaryAccent}>
                {children}
            </ButtonContent>
        </StyledTextButton>
    );
};
